<template>
  <AtroContent
    :class="['w-full flex-1', { 'h-full': layoutConfig.fullHeight }]"
    direction="col"
    items="center"
    wrap="nowrap"
  >
    <AtroContent
      v-auto-animate
      shrink
      :class="[
        'w-full max-w-[var(--home-content-width)] flex-1 sm:px-10 sm:pb-6 sm:pt-6',
      ]"
      wrap="nowrap"
      :direction="$isMobile.value ? 'col' : 'row'"
    >
      <MobileNavBar v-if="$isMobile.value" />
      <HomeSideBar v-else-if="!isMemberView" class="mr-12" />

      <AtroContent
        class="w-full flex-1 self-stretch px-4 py-4 sm:px-0 sm:py-0"
        direction="col"
        items="strech"
      >
        <NavList v-if="$isMobile.value" :nav-items class="mb-4" />
        <slot />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
const { isMemberView } = useHomeNav()
const { layoutConfig } = useLayoutConfig()
const { navItems } = useSideBarNav()
</script>
