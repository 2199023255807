export function useSideBarNav() {
  const router = useRouter()
  const navItems = computed<NavItem[]>(() => {
    const currentRouteName = router.currentRoute.value.name?.toString()
    if (currentRouteName?.includes('orgs-slug-dashboard')) {
      return [
        {
          icon: 'chart-line',
          label: 'Active Accounts',
          path: wrapPathWithParentOrg('/dashboard'),
        },
        {
          icon: 'rocket',
          label: 'Lead Generation',
          path: wrapPathWithParentOrg('/dashboard/leads'),
        },
        {
          icon: 'page',
          label: 'Template Library',
          path: wrapPathWithParentOrg('/dashboard/templates'),
        },
        {
          icon: 'building-outline',
          label: 'Partner Profile',
          path: wrapPathWithParentOrg('/dashboard/profile'),
        },
        {
          icon: 'eye',
          label: 'View an Org',
          action: 'open:org-switcher',
        },
      ]
    }
    return []
  })

  return { navItems }
}
