<template>
  <AtroContent
    :class="[
      'sticky left-0 z-10 w-[var(--home-sidebar-width)] space-y-8',
      topPositionClass,
    ]"
    direction="col"
    justify="between"
    wrap="nowrap"
  >
    <AtroContent v-auto-animate direction="col" wrap="nowrap">
      <AtroContent
        v-if="isAdminDashboardPage"
        class="gap-6"
        direction="col"
        wrap="nowrap"
      >
        <NuxtLink :to="wrapPathWithOrg('/score')">
          <PostureCard clickable shadowed />
        </NuxtLink>

        <AtroButton
          block
          id="dashboard-score-card"
          icon-right="shield-solid"
          text="Secure Something"
          type="primaryGradient"
          @click="openMarketplace()"
        />
      </AtroContent>

      <AtroHeading
        v-if="isPartnerPages"
        class="mb-8 !leading-none text-atro-gray"
        size="sm"
        text="Partner Dashboard"
      />
      <NavList v-if="navItems.length" :nav-items />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { wrapPathWithOrg } from '~/utils/navigate'

const router = useRouter()
const { showSubscribeBanner } = useCurrentSubscription()
const { layoutConfig } = useLayoutConfig()
const { navItems } = useSideBarNav()
const { openMarketplace } = useMarketplace()
useHasBottomToolbar({ mobileOnly: true })

const isAdminDashboardPage = computed(
  () => router.currentRoute.value.name === 'orgs-slug',
)
const isPartnerPages = computed(() =>
  router.currentRoute.value.name?.toString().includes('orgs-slug-dashboard'),
)

const topPositionClass = computed(() => {
  const hasAppNav = layoutConfig.value?.hasAppNav ?? false
  if (!hasAppNav) return 'top-6'
  return showSubscribeBanner ? 'top-[96px]' : 'top-24'
})
</script>
