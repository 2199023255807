<template>
  <AtroContent
    class="w-full space-x-4 py-2 sm:space-x-0 sm:space-y-3 sm:py-0"
    wrap="nowrap"
    :direction="$isMobile.value ? 'row' : 'col'"
  >
    <NuxtLink
      v-for="item in navItems"
      :key="item.label"
      :to="item.path"
      @click="onNavItemClick(item)"
    >
      <AtroButton
        :class="[
          'sm:space-x-2',
          {
            '!text-primary': router.currentRoute.value.path === item.path,
          },
        ]"
        type="flatGray"
        :icon-left="item.icon"
        :text="item.label"
      />
    </NuxtLink>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  navItems: NavItem[]
}

defineProps<Props>()
const router = useRouter()

const { openModal } = useModal()

function onNavItemClick(navItem: NavItem) {
  switch (navItem.action) {
    case 'open:org-switcher':
      openModal('orgSwitcher')
      break

    default:
      break
  }
}
</script>
